import { EngineStore } from '~/stores/engine'

export interface PlanBannerConfig {
  color?: string | null | undefined
  icon?: string | null | undefined
  priority?: number | null | undefined
}

export class PlanBanners {
  static async use() {
    const { getComponentContent } = Cms.use()
    const { content } = await getComponentContent('PlanBanners')

    const { flag } = FeatureFlags.use()

    const config = computed(() => flag<Record<string, PlanBannerConfig>>('plan-banner-config').value)

    const engine = EngineStore.use()

    const bannerCatalog = computed(() =>
      _isEmpty(config.value)
        ? _mapValues(engine.quotesCatalog, _ => null)
        : _mapValues(engine.quotesCatalog,
      (val) => _sortBy(val.planAttributes
        .filter(attr => !_isNil(content.value.ext[_snakeCase(attr)]))
        .map(attr =>
        {
          const conf = config.value?.[_snakeCase(attr)]

          return {
            text: content.value.ext[_snakeCase(attr)],
            color: conf?.color,
            icon: conf?.icon,
            priority: conf?.priority ?? 0
          }
        }
      ), x => +x.priority * -1)?.[0]))

    return {
      bannerCatalog
    }
  }
}